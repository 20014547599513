<template>
  <div class="accordion">
    <div class="accordion-item" v-for="(item, index) in accordionItems" :key="index">

      <button class="accordion-header" @click="toggleAccordion(index)" :data-title="item.title">
        {{ item.title }}
        <i v-if="item.content?.can_timeframe_change === 'Yes'" class="bx bxs-calendar-check text-success"></i>
        <i v-else-if="item.title === 'Add Notes'" class="bx bxs-pencil text-primary"></i> <!-- Icon for "Add Notes" -->
        <i v-else class="bx bxs-calendar-x text-danger"></i>
        <span>{{ activeIndex === index ? '⏶' : '⏷' }}</span>
      </button>

      <div ref="accordionBody" class="accordion-body-wrapper"
           :style="{ maxHeight: activeIndex === index ? accordionBodyHeight[index] + 'px' : '0px' }">
        <div class="accordion-body" :style="getBorderStyle(item.title)">
          <div v-if="!item.form">
            <p v-if="typeof item.content === 'string'">{{ item.content }}</p>
            <div v-else class="h-auto">
              <!-- <p><strong>Message:</strong> {{ item.content.query_message }}</p> -->
              <p><strong>Notes:</strong> {{ item.content.notes }}</p>
              <p><strong>Customer Response:</strong> {{ item.content.customer_response }}</p>
              <!-- <p><strong>Contact Methods:</strong> {{ item.content.contact_method }}</p> -->
              <!-- <p><strong>Call Outcome:</strong> {{ item.content.call_outcome }}</p> -->
              <p><strong>Time Fixed:</strong> {{
                  item.content.can_timeframe_change === 'Yes' ? 'Yes' : 'No'
                }}</p>
            </div>
          </div>


          <form v-if="item.form" @submit.prevent="submitForm">
            <div class="switch_form d-flex" style="margin-bottom: 8px;">
              <label>Time Fixed:</label>
              <div class="switch-container">
                <label class="switch">
                  <input type="checkbox" v-model="formData.can_timeframe_change">
                  <span class="slider"></span>
                </label>
              </div>
            </div>
            <!-- <div class="form-group">
              <label>Query Message</label>
              <textarea v-model="formData.query_message" required></textarea>
            </div> -->
            <div class="form-group">
              <label>Notes</label>
              <textarea v-model="formData.notes" required></textarea>
            </div>
            <div class="form-group">
              <label>Customer Response</label>
              <select v-model="formData.customer_response" required>
                <option value="">Select</option>
                <option value="0">No Interaction</option>
                <option value="1">No Response</option>
                <option value="2">Interested</option>
                <option value="3">Not Interested</option>
                <option value="4">Rescheduled</option>
                <option value="5">Confirmed</option>
                <option value="6">Declined</option>
              </select>
            </div>
            <!-- <div class="form-group">
              <label>Contact Methods</label>
              <select v-model="formData.contact_method" required>
                <option value="">Select</option>
                <option value="0">Phone</option>
                <option value="1">Email</option>
                <option value="2">Chat</option>
                <option value="3">Other</option>
              </select>
            </div> -->
            <!-- <div class="form-group">
              <label>Call Outcome</label>
              <select v-model="formData.call_outcome" required>
                <option value="">Select</option>
                <option value="0">No Answer</option>
                <option value="1">Successful</option>
                <option value="2">Unsuccessful</option>
              </select>
            </div> -->
            <button type="submit" class="submit-btn">Update</button>
          </form>

        </div>
      </div>
    </div>
  </div>
</template>


<script>
import {mapActions} from "vuex";

export default {
  name: "AppointmentInteractions",
  props: ['selectedAppointment'],
  emits: ['interactionResponse'],
  data() {
    return {
      activeIndex: null,
      accordionBodyHeight: [],
      formData: {
        contacted_by_id: "",
        appointment_id: "",
        contact_attempts: "",
        last_contacted_at: "",
        query_message: "",
        notes: "",
        customer_response: null,
        contact_method: null,
        call_outcome: null,
        can_timeframe_change: false
      },

    }
  },


  computed: {
    appointment() {
      return this.selectedAppointment?.appointment;
    },
    accordionItems() {
      if (!this.selectedAppointment?.appointment?.appointmentInteractions?.length) return [{
        title: "Add Notes",
        form: true
      }];
      let interactions = [...this.selectedAppointment?.appointment?.appointmentInteractions];
      const lastInteraction = interactions.pop();
      interactions.sort((a, b) => new Date(b.last_contacted_at) - new Date(a.last_contacted_at));

      let accordionItems = [
        {
          title: this.getDisplayDate(lastInteraction?.last_contacted_at) || "Last Interaction",
          content: lastInteraction
        },
        ...interactions.map((interaction) => ({
          title: `${this.getDisplayDate(interaction.last_contacted_at)}`,
          content: interaction,
        }))
      ];

      accordionItems.push({title: "Add Notes", form: true});

      return accordionItems;
    }
  },
  watch: {
    appointment(value) {
      this.formData.appointment_id = value.id;
      this.formData.contacted_by_id = this.$store.getters["appAuthLogin/authUser"]?.id;
    }
  },
  methods: {
    ...mapActions({
      postAppointmentInteractions: "appAppointmentInteractions/postAppointmentInteractions",
    }),

    getDisplayDate(date) {
      let newDate = date ? new Date(date) : new Date();
      return newDate.toLocaleString("en-AU", {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: true
      });
    },
    toggleAccordion(index) {
      if (this.activeIndex === index) {
        this.activeIndex = null;
      } else {
        this.activeIndex = index;
        this.$nextTick(() => {
          if (this.$refs.accordionBody[index]) {
            this.accordionBodyHeight[index] = this.$refs.accordionBody[index].scrollHeight;
          }
        });
      }
    },

    async submitForm() {
      try {
        const submissionData = {
          appointment_id: this.formData.appointment_id,
          contacted_by_id: this.formData.contacted_by_id,
          // query_message: this.formData.query_message,
          notes: this.formData.notes,
          customer_response: parseInt(this.formData.customer_response),
          // contact_method: parseInt(this.formData.contact_method) ,
          // call_outcome: parseInt(this.formData.call_outcome),
          can_timeframe_change: this.formData.can_timeframe_change ? 1 : 0,
          contact_attempts: 1,
          last_contacted_at: new Date().toISOString().slice(0, 19).replace("T", " "),
        };
        const response = await this.postAppointmentInteractions(submissionData);

        if (response.type === "success") {
          this.$emit('interactionResponse', response.data)
          this.formData.notes = ""
          this.formData.customer_response = ""
          this.formData.can_timeframe_change = ""
        } else {
          alert("Failed to update appointment interaction.");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
    getBorderStyle(title) {
      return {
        borderLeft: title === "Add Notes" ? "3px solid #5A8DEE" : "3px solid #15b9ac"
      };
    }
  }
}
</script>

<style scoped>

/* Accordion Styles */
.accordion {
  /* margin-top: 10px; */
}

.accordion-item {
  border-bottom: 1px solid #ddd;
}

.accordion-header {
  width: 100%;
  background: none;
  border: none;
  text-align: left;
  padding: 10px 15px;
  font-size: 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  color: rgb(65, 65, 65);
}

.accordion-item .accordion-header[data-title="Add Notes"] {
  background: #5A8DEE;
  color: white;
}

/* Smooth Accordion Body Animation */
.accordion-body-wrapper {
  overflow: hidden;
  transition: max-height 0.4s ease-in-out, opacity 0.4s ease-in-out;
  opacity: 1;
}

.accordion-body {
  padding: 10px 15px;
  background: #f9f9f9;
  font-size: 14px;
  border-left: 3px solid #5A8DEE;
}

/* Hide content when collapsed */
.accordion-body-wrapper[style*="max-height: 0px"] {
  opacity: 0;
}

/* Form Styles */
form {
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  height: 500px;
  overflow-y: auto;
  border-radius: 5px;
}


.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label, .switch_form label {
  font-weight: 500;
  font-size: 12px;
}

.switch-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-left: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 18px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 18px;
  transition: 0.4s;
}

.slider::before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #5A8DEE;
}

input:checked + .slider::before {
  transform: translateX(22px);
}

input, textarea, select {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  outline: none;
}

textarea {
  resize: vertical;
}


.submit-btn {
  background: #2c6de9;
  color: white;
  border: none;
  padding: 8px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  transition: background 0.3s;
}

.submit-btn:hover {
  background: #5A8DEE;
}
</style>