<script>
import MapRouteView from "@/views/backEnd/scheduleCalendar/MapRouteView.vue"
import AddressMixin from "@/components/backEnd/mixins/AddressMixin";

export default {
  name: "ViewRouteOnMapModal",
  components: {MapRouteView},
  mixins: [AddressMixin],
  props: {
    selectedTechnician: Object,
    availableAppointmentsData: Array
  },
  computed: {
    appointmentAddresses() {
      if (!this.availableAppointmentsData || !Array.isArray(this.availableAppointmentsData)) {
        return [];
      }

      return this.availableAppointmentsData
          .flatMap((item) =>
              item.appointment.map((appointment) => ({
                ...appointment,
                address: this.buildAddress(appointment.address),
                current_time_slot: item.timeSlot
              }))
          );
    },
    technicianAddress() {
      return this.buildAddress(this.selectedTechnician?.address);
    }
  },
};
</script>

<template>
  <div
      class="modal fade"
      id="viewMapModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="viewMapModal"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">Map Route - {{ selectedTechnician?.name }}</h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div class="row d-flex justify-content-center">
              <div>
                <MapRouteView :appointments="appointmentAddresses" :technician-current-address="technicianAddress"/>

                <div class="route-info">
                  <h3>Appointments:</h3>
                  <ul>
                    <li v-for="appointment in appointmentAddresses" :key="appointment.id">
                      <strong>{{ appointment.current_time_slot }}</strong> - <strong>{{appointment.reference}}</strong> {{ appointment.customer_name }}
                      ({{ appointment.service_name }})
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 pt-0">
          <button type="button" class="btn btn-primary" data-dismiss="modal">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Close</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.rounded-avatar {
  overflow: hidden;
  width: 60px;
  border-radius: 50% !important;
  border: 2px solid #7698d9 !important;
  border-bottom: none !important;
  border-left: none !important;
}

.total-info, .route-info {
  margin-top: 15px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
}
</style>