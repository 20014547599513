<script>
export default {
  name: "MapRouteView",
  props: {
    appointments: Array,
    technicianCurrentAddress: String,
  },
  data() {
    return {
      map: null,
      directionsService: null,
      directionsRenderer: null,
      routeDetails: [],
      totalDistance: null,
      totalDuration: null,
      marker: null,
      infoWindows: [],
      google: window.google,
    };
  },
  mounted() {
    this.initMap();
  },
  watch: {
    appointments: {
      handler() {
        this.renderMap();
      },
      deep: true,
    },
  },
  methods: {
    initMap() {
      if (!this.google) {
        console.error("Google Maps API not loaded!");
        return;
      }

      const lineSymbol = {
        path: this.google.maps.SymbolPath.CIRCLE,
        fillOpacity: 1,
        scale: 4
      };

      const polylineDotted = {
        strokeColor: '#9966ff',
        strokeOpacity: 0,
        fillOpacity: 0,
        icons: [{
          icon: lineSymbol,
          offset: '0',
          repeat: '10px'
        }],
      };
      this.map = new this.google.maps.Map(this.$refs.mapContainer, {
        center: {lat: -33.8688, lng: 151.2093},
        zoom: 10,
      });

      this.directionsService = new this.google.maps.DirectionsService();
      this.directionsRenderer = new this.google.maps.DirectionsRenderer({
        map: this.map,
        suppressMarkers: false, // Use default markers
        polylineOptions: polylineDotted
      });

      this.renderMap();
    },

    renderMap() {
      if (!this.appointments || this.appointments.length === 0) {
        console.warn("No locations provided.");
        return;
      }

      this.drawRoute();

    },

    drawRoute() {
      const origin = this.technicianCurrentAddress;
      const destination = this.appointments[this.appointments.length - 1]?.address;
      const waypoints = this.appointments.slice(0, -1).map((appointment) => ({
        location: appointment.address,
        stopover: true,
      }));

      const request = {
        origin,
        destination,
        waypoints,
        travelMode: this.google.maps.TravelMode.DRIVING,
      };

      this.directionsService.route(request, (result, status) => {
        if (status === this.google.maps.DirectionsStatus.OK) {
          this.directionsRenderer.setDirections(result);
          this.showDistanceDuration(result);
          // this.addCustomMarkers(result);
        } else {
          console.error("Directions request failed:", status);
        }
      });
    },

    showDistanceDuration(result) {
      this.clearInfoWindows();
      this.routeDetails = [];
      let totalDist = 0;
      let totalDur = 0;

      const legs = result.routes[0].legs;
      for (let i = 0; i < legs.length; i++) {
        const distance = legs[i].distance.text;
        const duration = legs[i].duration.text;

        this.routeDetails.push({
          start: legs[i].start_address,
          end: legs[i].end_address,
          distance,
          duration,
        });

        totalDist += legs[i].distance.value;
        totalDur += legs[i].duration.value;

        // Display InfoWindow at midpoint of segment
        const midPoint = this.getMidPoint(legs[i].start_location, legs[i].end_location);
        this.addInfoWindow(midPoint, `${distance} (${duration})`);
      }

      this.totalDistance = (totalDist / 1000).toFixed(2) + " km";
      this.totalDuration = (totalDur / 60).toFixed(2) + " mins";

      // Show total info at last stop
      const lastLocation = legs[legs.length - 1].end_location;
      this.addInfoWindow(lastLocation, `<b>Total: ${this.totalDistance} (${this.totalDuration})</b>`);
    },

    getMidPoint(start, end) {
      return {
        lat: (start.lat() + end.lat()) / 2,
        lng: (start.lng() + end.lng()) / 2,
      };
    },


    addInfoWindow(position, content) {
      const customContent = `
        <div style="
          background: #fff;
          padding: 5px 12px;
          border-radius: 8px;
          box-shadow: 2px 2px 10px rgba(0,0,0,0.2);
          font-size: 14px;
          color: #333;
          text-align: center;
          min-width: 120px;
        ">
          <img src="https://img.icons8.com/ios-filled/20/4CAF50/road.png" alt="Distance"/>
          ${content}
        </div>
      `;

      const infoWindow = new this.google.maps.InfoWindow({
        content: customContent,
        position,
      });

      infoWindow.open(this.map);
      this.infoWindows.push(infoWindow);
    },

    clearInfoWindows() {
      this.infoWindows.forEach((info) => info.close());
      this.infoWindows = [];
    },
  },
};
</script>

<template>
  <div>
    <div ref="mapContainer" class="map-container"></div>

    <!-- <p>{{technicianCurrentAddress.address.suburb}}</p> -->

    <!-- Total Distance & Duration -->
    <div v-if="totalDistance && totalDuration" class="total-info d-flex justify-content-between">
      <h4>Total Route Details:</h4>
      <p><strong>Distance:</strong> {{ totalDistance }}</p>
      <p><strong>Duration:</strong> {{ totalDuration }}</p>

    </div>

    <!-- Segment Details -->
    <div v-if="routeDetails.length > 0" class="route-info">
      <h3>Segmented Route Details</h3>
      <ul>
        <li v-for="(detail, index) in routeDetails" :key="index">
          <span>{{ detail.start }} → {{ detail.end }}</span>:
          <strong>{{ detail.distance }} ({{ detail.duration }})</strong>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
.map-container {
  width: 100%;
  min-width: 700px;
  position: relative;
  overflow: hidden;
  height: 500px;
}

.total-info, .route-info {
  margin-top: 15px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
}
</style>