<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper">
        <div class=" d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Appointment Schedule</h4>
        </div>
        <div class="users-list-filter px-1 my-2 py-2 border rounded">
          <div class="row">
            <div class="col-12 col-sm-6 col-md-3 col-lg-3">
              <div class="form-group">
                <label>Date</label>
                <div class="d-flex align-items-center date-picker-wrapper">
                  <div class="d-flex align-items-center">
                    <i class='bx bx-calendar'></i>
                  </div>
                  <div class="w-100 pl-1 ">
                    <div class="w-100 ">
                      <DatePicker v-model="getDataParams.selectedDate" :update-on-input="true"
                                  :min-date="new Date()"
                                  :masks="{input: ['DD MMMM YYYY']}"
                                  :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                        <template v-slot="{ inputValue, inputEvents }">
                          <input class="form-control" :value="inputValue" v-on="inputEvents"/>
                        </template>
                      </DatePicker>
                    </div>
                  </div>
                </div>
                <div class="text-danger"></div>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-3 col-lg-3 pt-2">
              <button type="reset" class="btn btn-primary btn-block glow users-list-clear mb-0"
                      @click="getData">Search
              </button>
            </div>

          </div>
        </div>
        <template v-if="isDataFilled && appointments.length > 0">
          <TuiCalendar
              :available-technicians="availableTechnicians"
              :available-time-slots="timeSlots"
              :available-appointments="availableAppointments"
              :user-types="userType"
              :selected-date="getDataParams.selectedDate"/>
        </template>
        <template v-else>
          <div class="text-center text-uppercase text-bold-700 bg-info p-1">
            <h4>No appointments found</h4>
          </div>
        </template>

      </section>
    </template>
  </AppLayout>
</template>

<script>
import TuiCalendar from '@/components/backEnd/TuiCalender.vue';
import {mapActions, mapGetters} from "vuex";
import AppLayout from "@/layouts/backEnd/AppLayout.vue";
import Loader from "@/components/backEnd/mixins/Loader";
import {DatePicker} from "v-calendar";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";

export default {
  components: {
    TuiCalendar,
    DatePicker,
    AppLayout
  },
  mixins: [Loader, ShowToastMessage],
  data() {
    return {
      getAvailableTechniciansParams: {
        requested_date: '',
        state: 'nsw',
        panel: 1
      },
      getTechniciansPrams: {
        status: 1,
        with_relation: ['user', 'employee.address']
      },
      getAppointmentsParams: {
        date: [],
        with_relation: [
          'address',
          'billingAddress',
          'customer.user',
          'customer.address',
          'order',
          'service',
          'appointmentCreator',
          'technicianAppointment.technician.user',
          'appointmentSchedules',
          'appointmentInteractions',
          'appointmentInteractions.contactedBy'
        ],
        where_has_address_state: '',
        order_by_time: 'ASC',
        order_by_date: 'ASC',
      },
      timeSlots: [
        "08:00 AM", "09:00 AM", "10:00 AM", "11:00 AM", "12:00 PM",
        "01:00 PM", "02:00 PM", "03:00 PM", "04:00 PM", "05:00 PM", "06:00 PM",
        "07:00 PM", "08:00 PM", "09:00 PM",
      ],
      getDataParams: {
        selectedDate: '',
      },
      isDataFilled: false,
    }
  },
  computed: {
    ...mapGetters({
      technicians: 'appTechnicians/technicians',
      appointments: 'appAppointments/appointments',
      technicianTimeSlotByDate: 'appTechnicians/technicianTimeSlotsByDate',
      userType: "appUsers/userType",
    }),
    availableAppointments() {
      let assigned = {};
      let unassigned = {};

      // Initialize assigned structure with all technicians and time slots
      this.availableTechnicians.forEach((technician) => {
        assigned[technician.id] = {};

        this.timeSlots.forEach((slot) => {
          assigned[technician.id][slot] = [];
        });
      });

      // Loop through the appointments to classify them
      this.appointments.forEach((appointment) => {
        const technicianId = appointment?.technicianAppointment?.technician?.id;
        const time = appointment?.time;

        const appObj = {
          id: appointment?.id,
          reference: appointment?.reference,
          status: appointment?.status,
          preference: appointment?.preference,
          date: appointment?.date,
          time: time,
          service_name: appointment?.service?.name,
          service_slug: appointment?.service?.slug,
          customer_id: appointment?.customer?.id,
          customer_name: appointment?.customer?.user?.full_name,
          customer_email: appointment?.customer?.user?.email,
          customer_phone: appointment?.customer?.user?.phone_number,
          user_id: appointment?.customer?.user?.id,
          technicianId: technicianId ?? null,
          address: appointment?.address,
          billing_address: appointment?.billingAddress,
          schedules: appointment?.appointmentSchedules,
          appointmentInteractions: appointment?.appointmentInteractions
        }
        if (technicianId) {
          // Ensure technicianId exists in assigned structure
          if (!assigned[technicianId]) {
            assigned[technicianId] = {};
            this.timeSlots.forEach((slot) => {
              assigned[technicianId][slot] = [];
            });
          }

          // Assign appointment to correct technician and time slot
          assigned[technicianId][time].push({...appObj});
        } else {
          // If no technician is assigned, add to unassigned
          if (!unassigned[time]) {
            unassigned[time] = [];
          }

          // Add appointment to unassigned
          unassigned[time].push({...appObj});
        }
      });

      // Return the final result as an object containing both assigned and unassigned appointments
      return {assigned, unassigned};
    },

    availableTechnicians() {
      return this.technicianTimeSlotByDate.map(timeSlot => {
        // Find the corresponding technician for the current time slot
        const technician = this.technicians.find(tech => tech.id === timeSlot.id);

        // If technician not found, return null to skip
        if (!technician) {
          return null;
        }
        // Merge the technician data with the available time slots
        return {
          id: technician.id,
          name: `${technician.user.first_name} ${technician.user.last_name}`,
          userId: technician.user.id,
          employeeId: technician.employee.id,
          email: technician.user.email ?? null,
          schedule: timeSlot?.available_time_slots ?? [],
          address: technician.employee?.address ?? null,
        };
      }).filter(technician => technician !== null);

    }
  },
  methods: {
    ...mapActions({
      getTechnicians: 'appTechnicians/getTechnicians',
      getAppointments: 'appAppointments/getAppointments',
      getAvailableTechnicianTimeSlot: 'appTechnicians/getAvailableTechniciansByDate',
      getUserType: "appUsers/getUserType",
    }),
    async getUserTypes() {
      const paramObj = {
        id: this.$store.getters["appAuthLogin/authUser"].id,
      };
      await this.getUserType(paramObj.id);
    },

    async getData() {
      const selectedDate = this.getDataParams.selectedDate;
      if (!selectedDate) {
        this.showToastMessage({
          type: 'warning',
          message: 'Please select a date'
        })
        return false;
      }
      this.getAvailableTechniciansParams.requested_date = selectedDate;
      this.getAppointmentsParams.date = [selectedDate, selectedDate];
      this.loader(true);
      await this.getAvailableTechnicianTimeSlot(this.getAvailableTechniciansParams);
      await this.getAppointments(this.getAppointmentsParams);
      this.loader(false);
      this.isDataFilled = true;
    }

  },
  async mounted() {
    this.loader(true)
    await this.getTechnicians(this.getTechniciansPrams)
    /* await this.getAvailableTechnicianTimeSlot(this.getAvailableTechniciansParams);
     await this.getAppointments(this.getAppointmentsParams)*/
    await this.getUserTypes()
    this.loader(false)
  }
};
</script>
