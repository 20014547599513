<template>
  <div>
    <h3 class="text-center mb-4">Technician Appointment Schedule {{ this.selectedDate }}</h3>

    <table class="table table-bordered">
      <!-- Time Slot Header -->
      <thead>
      <tr class="font-weight-bold bg-light">
        <th class="py-2">Technicians</th>
        <th v-for="(slot, index) in timeSlots" :key="index" class="py-2 text-center">
          {{ slot }}
        </th>
      </tr>
      </thead>

      <!-- Technician Rows -->
      <tbody>
      <tr v-for="technician in technicians" :key="technician.id">
        <!-- Technician Name -->
        <td class="py-0 font-weight-bold text-uppercase">
          <span style="font-size: 12px">{{ technician.name }}</span>
          <br>
          <button
              style="border: none; padding: 2px 5px; color: white; background: #00CFDDFF; border-radius: 5px; font-size:12px;"
              @click="openViewMapModal(technician.id)">view map
          </button>
        </td>

        <!-- Time Slot Columns with Draggable  Assigned Appointments -->
        <td
            v-for="(slot) in timeSlots"
            :key="`${technician.id}-${slot}`"
            :data-tech-id="technician.id"
            :data-time-slot="slot"
            style="padding: 5px; background-color: #c6c0f3"
            :class="{'past-slot': isPastSlot(slot),'active-tech-slot': isTechActive(technician.id,slot)}"
        >
          <draggable
              tag="div"
              :list="assignedAppointments[technician.id]?.[slot] || []"
              :item-key="appointment => appointment.id"
              :key="`${technician.id}-${slot}`"
              group="appointments"
              @start="onDragStart"
              @end="onDragEnd"
              :move="onMoving"
              class="appointment-container"
              chosen-class="chosen"
              delay="200"
              handle=".move_icon"
          >
            <template #item="{ element }">
              <div class="card assigned"
                   :ref="`appointment-${element.id}`"
                   :data-id="element.id"
                   :id="`appointment-${element.id}`"
                   :class="{'dragging': isDragging && currentDraggedAppointmentId === element.id}"

              >
                <div class="card-header text-bold-600 text-uppercase w-100 m-0 p-0"
                     style="padding: 1px"
                     :style="{backgroundColor: appointmentColors[element?.status]?.bg ?? '#82FA935A',
                       color: appointmentColors[element?.status]?.text ?? '#00FF2CFF'}">

                  <div class="status_row text-center mb-0">
                    <span @click="toggleSidebar($event, element)" class=" mb-0 cursor-pointer"><i
                        class="bx bx-edit bx-xs"></i></span>
                    <span class="move_icon mb-0" style="border-right: 1px dashed red; border-left: 1px dashed red">{{
                        element.status === 'Rescheduled & Reassigned' ? 'R & R' : element.status
                      }}</span>
                    <span class="right_icon mb-0" @click="openPlayCardModal($event, element)"><i
                        class='bx bx-add-to-queue bx-xs'></i></span>
                  </div>

                  <div class="border-top-dark reference_row text-center">
                    <span class="left_icon"><i class='bx bxs-chevrons-left bx-xs'></i></span>
                    <span class="status" style="border-right: 1px dashed red; border-left: 1px dashed red">{{
                        element.reference
                      }}</span>
                    <span class="right_icon"><i class='bx bxs-chevrons-right bx-xs'></i></span>
                  </div>
                </div>
                <div class="icon_tab">
                  <span :style="{color: getSelectedView(element.reference) === 'name'?
                   appointmentColors[element?.status]?.text: ''}" class="cursor-pointer"
                        @click="selectedView[element.reference] = 'name'">
                    <i class="bx bxs-user"></i></span>
                  <span :style="{color: getSelectedView(element.reference) === 'service'?
                   appointmentColors[element?.status]?.text: ''}" class="cursor-pointer"
                        @click="selectedView[element.reference] = 'service'">
                    <i class="bx bxs-cube-alt"></i></span>
                  <span :style="{color: getSelectedView(element.reference) === 'phone'?
                   appointmentColors[element?.status]?.text: ''}" class="cursor-pointer"
                        @click="selectedView[element.reference] = 'phone'">
                    <i class="bx bx-phone-call"></i></span>
                  <span :style="{color: getSelectedView(element.reference) === 'location'?
                   appointmentColors[element?.status]?.text: ''}" class="cursor-pointer"
                        @click="selectedView[element.reference] = 'location'">
                    <i class='bx bxs-map'></i></span>
                </div>
                <div class="card-body text-bold-700">
                  <p v-if="getSelectedView(element.reference) === 'name'">{{ element.customer_name }}</p>
                  <p v-if="getSelectedView(element.reference) === 'service'">{{ element.service_name }}</p>
                  <p v-if="getSelectedView(element.reference) === 'phone'">{{ element.customer_phone }}</p>
                  <p v-if="getSelectedView(element.reference) === 'location'">{{ buildAddress(element.address) }}</p>
                </div>
                <div>
                </div>
              </div>
            </template>
          </draggable>
        </td>
      </tr>
      <!-- Time Slot Columns with Draggable  Unassigned Appointments -->
      <tr class="bg-rgba-secondary">

        <td class="py-2 font-weight-bold text-center text-uppercase">Unassigned Appointments</td>

        <td
            v-for="(slot) in timeSlots"
            :key="`appointment-${slot}`"
            :data-time-slot="slot"
            style="padding: 5px;"
        >
          <draggable
              tag="div"
              :list="unassignedAppointments[slot]"
              group="appointments"
              @start="onDragStart"
              @end="onDragEnd"
              :move="onMoving"
              class="appointment-container"
              :item-key="appointment => appointment.id"
              :key="`appointment-${slot}`"
              chosen-class="chosen"
              delay="200"
          >
            <template #item="{ element }">
              <div class="card unassigned"
                   :ref="`appointment-${element.id}`"
                   :data-id="element.id"
                   :id="`appointment-${element.id}`"
              >
                <div class="card-header text-bold-500 text-uppercase w-100 justify-content-center"
                     style="padding: 1px"
                     :style="{backgroundColor: appointmentColors[element?.status]?.bg ?? '#82FA935A',
                       color: appointmentColors[element?.status]?.text ?? '#00FF2CFF'}">
                  <span>{{
                      element.status === 'Rescheduled & Reassigned' ? 'R & R' : element.status
                    }}</span>|<span>{{ element.reference }}</span>
                </div>
                <div
                    style="padding: 0px 1px; margin-bottom: 0px; display: flex; justify-content: space-between; align-items: center; border-bottom: 1px solid gainsboro;">
                  <span :style="{color: getSelectedView(element.reference) === 'name'?
                   appointmentColors[element?.status]?.text: ''}" class="cursor-pointer"
                        @click="selectedView[element.reference] = 'name'">
                    <i class="bx bxs-user"></i></span>
                  <span :style="{color: getSelectedView(element.reference) === 'service'?
                   appointmentColors[element?.status]?.text: ''}" class="cursor-pointer"
                        @click="selectedView[element.reference] = 'service'">
                    <i class="bx bxs-cube-alt"></i></span>
                  <span :style="{color: getSelectedView(element.reference) === 'phone'?
                   appointmentColors[element?.status]?.text: ''}" class="cursor-pointer"
                        @click="selectedView[element.reference] = 'phone'">
                    <i class="bx bx-phone-call"></i></span>
                  <span :style="{color: getSelectedView(element.reference) === 'location'?
                   appointmentColors[element?.status]?.text: ''}" class="cursor-pointer"
                        @click="selectedView[element.reference] = 'location'">
                    <i class='bx bxs-map'></i></span>
                </div>
                <div class="card-body text-bold-700">
                  <p v-if="getSelectedView(element.reference) === 'name'">{{ element.customer_name }}</p>
                  <p v-if="getSelectedView(element.reference) === 'service'">{{ element.service_name }}</p>
                  <p v-if="getSelectedView(element.reference) === 'phone'">{{ element.customer_phone }}</p>
                  <p v-if="getSelectedView(element.reference) === 'location'">{{ buildAddress(element.address) }}</p>
                </div>
              </div>
            </template>
          </draggable>
        </td>
      </tr>

      </tbody>
      <div class="sidebar_container">
        <div v-if="isOpen" class="overlay" @click="this.isOpen = false"></div>
        <div class="sidebar" :class="{'sidebar-open': isOpen}">
          <div class="sidebar-content">
            <div class="sidebar_header text-bold-600 text-uppercase"
                 :style="{backgroundColor: appointmentColors[interactionAppointment?.appointment?.status]?.bg ?? '#82FA935A',
                       color: appointmentColors[interactionAppointment?.appointment?.status]?.text ?? '#00FF2CFF'}">
              <h2>Notes</h2>
              <p>{{ interactionAppointment?.appointment?.reference }} |
                {{
                  interactionAppointment?.appointment?.status === 'Rescheduled & Reassigned' ? 'R & R' : interactionAppointment?.appointment?.status
                }}</p>
              <span @click="this.isOpen = false">✖</span>
            </div>
            <AppointmentInteractions :selected-appointment="interactionAppointment"
                                     @interaction-response="onInteractionResponse"/>
          </div>
        </div>
      </div>
    </table>
    <AppointmentDetailsCardModal
        :user-types="userAllTypes"
        :moved-status="movedStatus"
        :appointment="appointment"
        @change-status="onChangeStatus"
    />

    <ViewRouteOnMapModal
        :selected-technician="selectedTechnician"
        :available-appointments-data="availableAppointmentsData"
    />
    <div class="" data-toggle="modal" data-target="#appointmentDetailsCardModal"></div>
    <div class="" data-toggle="modal" data-target="#viewMapModal"></div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import AddressMixin from "@/components/backEnd/mixins/AddressMixin";
import Authorization from "@/components/backEnd/mixins/Authorization";
import AppointmentDetailsCardModal from "@/views/backEnd/scheduleCalendar/AppointmentDetailsCardModal.vue";
import ToggleModal from "@/components/backEnd/mixins/ToggleModal";
import GlobalMixin from "@/components/backEnd/mixins/GlobalMixin";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import ViewRouteOnMapModal from "@/views/backEnd/scheduleCalendar/ViewRouteOnMapModal.vue";
import AppointmentInteractions from "@/views/backEnd/scheduleCalendar/AppointmentInteractions.vue";

export default {
  components: {draggable, AppointmentDetailsCardModal, ViewRouteOnMapModal, AppointmentInteractions},
  props: ['availableTechnicians', 'availableTimeSlots', 'availableAppointments', 'userTypes', 'selectedDate'],
  mixins: [ShowToastMessage, AddressMixin, Authorization, ToggleModal, GlobalMixin],
  data() {
    return {
      currentTimeSlot: this.getCurrentAustraliaTime24Format(),
      currentDate: this.selectedDate,
      appointment: {},
      movedStatus: {},
      isDropAllowed: true,
      isDragging: false,
      currentDraggedAppointmentId: null,
      draggedAppointmentInfo: {},
      selectedView: {},
      assignedAppointments: this.availableAppointments?.assigned,
      unassignedAppointments: this.availableAppointments?.unassigned,
      appointmentColors: {
        Open: {text: "#03CC22", bg: "#82FA935A"},
        OnGoing: {text: "#FDAC41FF", bg: "#FDAC412B"},
        Closed: {text: "#39DA8AFF", bg: "#39DA8A2B"},
        Canceled: {text: "#FF5B5CFF", bg: "#FF5B5C2B"},
        Credited: {text: "#5A8DEEFF", bg: "#5A8DEE2B"},
        Delivered: {text: "#39DA8AFF", bg: "#39DA8A2B"},
        Dispute: {text: "#FF5B5CFF", bg: "#FF5B5C2B"},
        Hold: {text: "#FF5B5CFF", bg: "#FF5B5C2B"},
        Reassigned: {text: "#00CFDDFF", bg: "#00CFDD2B"},
        Refund: {text: "#475F7BFF", bg: "#475F7B2B"},
        Rescheduled: {text: "#00CFDDFF", bg: "#00CFDD2B"},
        Return: {text: "#F76A2AFF", bg: "#FDAC412B"},
        'Rescheduled & Reassigned': {text: "#00CFDDFF", bg: "#00CFDD2B"},
        'R & R': {text: "#00CFDDFF", bg: "#00CFDD2B"},
      },
      availableAppointmentsData: [],
      selectedTechnician: null,
      interactionAppointment: {},
      isOpen: false,
    };
  },
  computed: {
    userAllTypes() {
      return this.userTypes
    },
    technicians() {
      return this.availableTechnicians
    },
    timeSlots() {
      return this.availableTimeSlots
    },
    getSelectedView() {
      return (reference) => {
        return this.selectedView?.[reference] || 'name';
      };
    },
    backupAssignedAppointment() {
      return JSON.parse(JSON.stringify(this.availableAppointments?.assigned))
    },
    backupUnassignedAppointment() {
      return {...this.availableAppointments?.unassigned}
    }
  },
  watch: {
    "availableAppointments.assigned": {
      handler(newVal) {
        this.assignedAppointments = newVal;
      },
      deep: true, // Ensures Vue detects nested object changes
    },
    "availableAppointments.unassigned": {
      handler(newVal) {
        this.unassignedAppointments = newVal;
      },
      deep: true, // Ensures Vue detects nested object changes
    },
  },
  methods: {
    onInteractionResponse(value) {
      const {techId, timeSlot} = this.interactionAppointment;
      const appointment = this.assignedAppointments[techId]?.[timeSlot]?.[0];
      if (appointment) {
        appointment.appointmentInteractions.unshift(value)
      }
      console.log("emit", value)
    },
    toggleSidebar(event, element) {
      const techId = event.target.closest('[data-tech-id]')?.dataset.techId;
      const timeSlot = event.target.closest('[data-time-slot]')?.dataset.timeSlot;
      this.isOpen = !this.isOpen;
      this.interactionAppointment = {techId, timeSlot, appointment: element}
    },

    async onChangeStatus(data) {
      const {changedStatus} = data || {};
      const {selectedTime, selectedDate, statusText, technician} = changedStatus || {};
      const {id: technicianId} = technician || {};

      if (!technicianId || !selectedTime || !selectedDate) {
        console.error('Missing data to update status');
        return;
      }

      const appointment = this.assignedAppointments[technicianId]?.[selectedTime]?.[0];
      await this.$nextTick(() => {
        if (appointment) {
          this.assignedAppointments[technicianId][selectedTime] = [{
            ...appointment,
            time: selectedTime,
            date: selectedDate,
            status: statusText,
            technicianId,
          }];
        } else {
          console.error(`Appointment not found for technicianId: ${technicianId} at selectedTime: ${selectedTime}`);
        }
      });
    },

    async openPlayCardModal(event, element) {
      const techId = event.target.closest('[data-tech-id]')?.dataset.techId;
      const timeSlot = event.target.closest('[data-time-slot]')?.dataset.timeSlot;
      const changeContext = {
        original: {
          technicianId: element?.technicianId ?? null,
          time: element.time
        },
        moved: {
          technicianId: parseInt(techId),
          time: timeSlot
        }
      }
      const technician = this.technicians.find((item) => item.id === parseInt(techId));
      this.movedStatus = {
        ...this.getStatus(changeContext),
        selectedDate: this.currentDate,
        selectedTime: timeSlot,
        technician
      }
      // no status changes does not need to  open modal
      if (this.movedStatus.statusValue === null) {
        this.showToastMessage({
          type: 'warning',
          message: 'No position changed'
        })
        return false;
      } else {
        this.appointment = element
        await this.toggleModal('appointmentDetailsCardModal');
      }
    },

    async openViewMapModal(technicianId) {

      this.selectedTechnician = await this.technicians.find((tech) => tech.id === technicianId);

      let matchedAppointments = await this.assignedAppointments[technicianId];

      this.availableAppointmentsData = Object.entries(matchedAppointments)
          .filter(([timeSlot, appointment]) => timeSlot && appointment.length > 0)
          .map(([timeSlot, appointment]) => ({timeSlot, appointment}));

      if (this.availableAppointmentsData.length === 0) {
        this.showToastMessage({
          type: 'warning',
          message: 'No appointment are assigned on current tech'
        });
        return false;
      }
      await this.toggleModal('viewMapModal');
    },

    getStatus(changeContext) {
      const {original, moved} = changeContext;
      if (!original.technicianId && original.time === moved.time) {
        return {statusText: "Open", statusValue: 0}; // If no technician was assigned before but customer desire time
      }
      if (!original.technicianId && original.time !== moved.time) {
        return {statusText: "R & R", statusValue: 10}; // If no technician was assigned before but different time
      }

      if (original.technicianId !== moved.technicianId && original.time === moved.time) {
        return {statusText: "Reassigned", statusValue: 7}; // Different technician but same time
      }

      if (original.technicianId === moved.technicianId && original.time !== moved.time) {
        return {statusText: "Rescheduled", statusValue: 9}; // Same technician but different time
      }

      if (original.technicianId !== moved.technicianId && original.time !== moved.time) {
        return {statusText: "R & R", statusValue: 10}; // Different technician and different time
      }

      return {statusText: "No Change", statusValue: null}; // If none of the above conditions match (shouldn't happen)
    },

    // Get the current time slot (round down to the nearest hour)
    getCurrentAustraliaTime24Format() {
      const now = new Date();
      const options = {timeZone: 'Australia/Sydney', hour: '2-digit', minute: '2-digit', hourCycle: 'h23'};
      return new Intl.DateTimeFormat('en-AU', options).format(now);
    },

    isTechActive(techId, slot) {
      return this.technicians
          .find(tech => tech.id === techId)
          ?.schedule.find(timeSlot => timeSlot.name === slot)
          ?.status !== 1;
    },

    // Check if a given time slot is in the past
    isPastSlot(slot) {
      const selectedDateTime = this.getDateTime(new Date(`${this.selectedDate} 00:00:00`));
      const currentDataTime = this.getAustraliaDateTime('Australia/Sydney');
      if (selectedDateTime > currentDataTime) {
        return false
      } else {
        return this.convertTime12To24Format(slot) <= this.getCurrentAustraliaTime24Format();
      }
    },

    onMoving(evt) {
      const targetSlot = evt.to.closest('[data-time-slot]')?.dataset.timeSlot;
      const targetTechId = evt.to.closest('[data-tech-id]')?.dataset.techId;
      const parsedTargetTechId = targetTechId ? parseInt(targetTechId, 10) : null;

      const sourceTechId = evt.from.closest('[data-tech-id]')?.dataset.techId ?? null;
      const draggedElement = evt.draggedContext.element;
      //console.log(targetSlot,draggedElement.technicianId, evt.relatedContext, parsedTargetTechId, sourceTechId)

      if (parsedTargetTechId === null) {
        return false
      }
      if (evt.relatedContext.list === null && draggedElement?.technicianId === null) {
        return false
      }
      // Prevent item dropping if slot have an item means appointment
      if (evt.relatedContext.list.length > 0) {
        return false;
      }
      // Prevent dropping into past slots & technician active status
      if (this.isPastSlot(targetSlot) || this.isTechActive(parsedTargetTechId, targetSlot)) {
        return false;
      }
      if (sourceTechId === null || draggedElement.technicianId === null) {
        return true
      }
      /*const targetTechId = evt.to.closest('[data-tech-id]')?.dataset.techId;
      const targetSlot = evt.to.closest('[data-time-slot]')?.dataset.timeSlot;
      const targetAppointments = this.assignedAppointments[targetTechId]?.[targetSlot];

      if (targetAppointments && targetAppointments.length > 0) {
        // evt.related.classList.add('no-drop');
        this.isDropAllowed = false;
        return false; // Prevent move if the target slot is occupied
      } else {
        this.isDropAllowed = true;
        // Allow move if target slot is empty or has space
        return true;
      }*/

    },

    onDragStart(evt) {
      this.isDragging = true;
      this.currentDraggedAppointmentId = parseInt(evt.item.dataset.id);
    },

    onDragEnd(evt) {
      this.isDragging = false;
      this.$nextTick(() => {
        // Check if the dragged item itself is the `.card`
        let card = evt.to.classList.contains("card") ? evt.item : evt.to.querySelector(".card");

        if (card) {
          card.classList.add("dropped");
        } else {
          console.error("Card element still not found. Check HTML structure.");
        }
      });
      this.currentDraggedAppointmentId = null

      /*const droppedItemId = evt.item.getAttribute('data-id');
      console.log(droppedItemId)
      if (droppedItemId) {
        // Construct the dynamic ID selector
        const droppedItem = document.getElementById(`appointment-${droppedItemId}`);
        console.log('dropitem',droppedItem)
        if (droppedItem) {
          draggedItem.classList.add('dropped'); // Add the class to the dropped element
        }
      }*/

      /*this.$nextTick(() => {
        this.assignedAppointments[newTechId][newTimeSlot].find((appointment) => appointment.id === this.currentDraggedAppointmentId)
      });*/
      /*if (!this.assignedAppointments[newTechId]) {
        // If not, create it
        this.assignedAppointments[newTechId] = {};
      }

      // Check if the new time slot exists for the technician
      if (!this.assignedAppointments[newTechId][newTimeSlot]) {
        // If not, create the time slot
        this.assignedAppointments[newTechId][newTimeSlot] = [];
      }
      const appointment = this.assignedAppointments[newTechId][newTimeSlot].find((appointment) => appointment.id === this.currentDraggedAppointmentId)
      // Add the appointment to the new time slot and technician
      //this.assignedAppointments[newTechId][newTimeSlot].push(appointment);

      console.log(`moved`, appointment)*/

      /*if (movedAppointment) {
        const prevIndex = this.assignedAppointments[previousTechId][previousTimeSlot].findIndex(
            (appointment) => {
              return appointment.id === movedAppointment.id
            });
        console.log(`pre:`, prevIndex)

        /!* if (prevIndex !== -1) {
           this.appointments[previousTechId][previousTimeSlot].splice(prevIndex, 1);
         }

         const targetTimeSlotAppointments = this.appointments[technicianId][timeSlot];
         targetTimeSlotAppointments.push(movedAppointment);

         console.log("Moved appointment:", movedAppointment);
         console.log("Previous Tech:", previousTechId, "Previous Time:", previousTimeSlot);
         console.log("New Tech:", technicianId, "New Time:", timeSlot);
         *!/
      }*/
    },

  },


};
</script>

<style scoped>
.card {
  margin-bottom: 0;
  width: 100%;
  border-radius: 5px 5px 0 0;
  height: 85px;
  overflow: hidden;
  box-shadow: none;
}

.card-body {
  padding: 0 5px;
  height: 35px;
  width: 154px;
  font-size: 12px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}

/* Custom scrollbar styles */
.card-body::-webkit-scrollbar {
  height: 4px;
}

.card-body::-webkit-scrollbar-thumb {
  background: #888;
}

.card-body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.card-body p {
  margin-top: 1px;
  line-height: 13px;
  font-weight: 400;
}

td, th {
  min-width: 170px;
  height: 87px;
}

.move_icon {
  cursor: grab !important;
}

.dragging {
  opacity: 0.8;
  border: 2px dashed blue;
}

.dropped {
  border: 2px dashed red !important;
}

.chosen {
  border: 2px dashed yellowgreen;
}

.no-drop {
  cursor: not-allowed !important;
}

.reference_row, .status_row {
  width: 100%;
  display: grid;
  grid-template-columns: 30px 1fr 30px;
  height: 20px !important;
  align-items: center;
  font-size: 12px;

}


.icon_tab {
  height: 20px;
  padding: 0 5px;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid gainsboro;
}

.right_icon, .left_icon {
  cursor: pointer;
}

.past-slot {
  background: repeating-linear-gradient(
      145deg,
      #ffcc00, /* Yellow Stripes */ #ffcc00 10px,
      #ccc 10px, /* Gray Background */ #ccc 20px
  );
  opacity: 0.6;
  /*pointer-events: none; *//* Disable clicking */
}

.active-tech-slot {
  background: repeating-linear-gradient(
      145deg,
      #ff002f, /* Yellow Stripes */ #da1212 10px,
      #ccc 10px, /* Gray Background */ #ccc 20px
  );
  opacity: 0.6;
  /*pointer-events: none; *//* Disable clicking */
}


.table {
  position: relative;
  overflow: hidden;
}

.menu-button {
  position: fixed;
  top: 15px;
  left: 15px;
  padding: 10px 15px;
  font-size: 20px;
  font-weight: bold;
  background: blue;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  z-index: 1000;
}

.sidebar {
  position: absolute;
  top: 0;
  left: -410px;
  width: 400px;
  height: 100%;
  background: white;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
  z-index: 900;
}

.sidebar-open {
  transform: translateX(410px);
}

.sidebar-content {
  height: 100%;
  overflow-y: auto;
}

/* Custom Scrollbar for Sidebar */
.sidebar-content::-webkit-scrollbar {
  width: 4px;
}

.sidebar-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.sidebar-content::-webkit-scrollbar-thumb {
  background: #888;
}

.sidebar-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.sidebar_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid gainsboro;
}

.sidebar_header span {
  border: none;
  color: rgb(255, 61, 61);
  background: none;
  cursor: pointer;
}

.sidebar-content h2 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}

.sidebar-content p {
  font-size: 16px;
  margin-bottom: 0;
}

.sidebar-content ul {
  list-style: none;
  padding: 0;
}

.sidebar-content ul li {
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}

.sidebar-content ul li:hover {
  background: #f5f5f5;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 800;
}
</style>
