<script>
import {mapActions} from "vuex";
import GlobalMixin from "@/components/backEnd/mixins/GlobalMixin";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import ToggleModal from "@/components/backEnd/mixins/ToggleModal";

export default {
  name: "appointmentDetailsCardModal",
  mixins: [ShowToastMessage, Loader, GlobalMixin, ToggleModal],
  emits: ['changeStatus'],
  props: {
    userTypes: {
      required: true,
      default: () => []
    },
    movedStatus: {
      required: true,
      default: () => {
      }
    },
    appointment: {
      required: true,
      default: () => {
      }
    }
  },
  data() {
    return {
      selectedStatus: '',
      allAppointmentStatus: [],
      selectedAppointment: {
        id: null,
        appointmentSchedules: []
      },
      changedStatus: {},
      userAllType: [],
      requestByCustomer: false,
      emailNotifyToCustomer: false,
      haveNote: false,
      visibleNoteToCustomer: false,
      selectedUserType: '',
      noteDescription: '',
      statusPreUpdateChargeResponse: {},
      frontendErrors: {
        selectedUserType: '',
        noteDescription: '',
      }
    }
  },
  watch: {
    'userTypes': {
      handler(newVal) {
        this.userAllType = newVal
      },
      deep: true,
      immediate: true
    },
    'appointment': {
      handler(newVal) {
        this.selectedAppointment = newVal
      },
      deep: true
    },
    'movedStatus': {
      async handler(newVal) {
        this.changedStatus = newVal
        await this.$nextTick(); // Wait for DOM and data updates
        if (newVal.statusValue !== 7 && newVal.statusValue) {
          await this.triggerStatusUpdate();
        }

      },
      deep: true
    },
    requestByCustomer() {
      this.triggerStatusUpdate();
    },

  },
  computed: {
    isVisibleRequestByCustomer() {
      return [9, 10].includes(this.movedStatus.statusValue);
    }
  },

  methods: {
    ...mapActions({
      putAppointmentStatus: 'appAppointments/putAppointmentStatus',
      postAppointmentSchedule: 'appAppointmentSchedules/postAppointmentSchedule',
      postAppointmentHistory: 'appAppointments/postAppointmentHistory',
      putAppointmentStatusCharge: 'appAppointments/putAppointmentStatusCharge',
      postAppointmentStatusUpdateNotifyEmail: 'appAppointments/postAppointmentStatusUpdateNotifyEmail',
      postCustomerOpinionRequestEmail: 'appCustomerOpinions/postCustomerOpinionRequestEmail',
      postAppointmentTechnician: 'appAppointments/postAppointmentTechnician',
    }),
    resetData() {
      //Object.assign(this.$data, this.$options.data.apply(this));
      this.requestByCustomer = false;
      this.emailNotifyToCustomer = false;
      this.haveNote = false;
      this.visibleNoteToCustomer = false;
      this.selectedUserType = '';
      this.noteDescription = '';
      this.statusPreUpdateChargeResponse = {};
      this.frontendErrors = {
        selectedUserType: '',
        noteDescription: '',
      };
    },

    async triggerStatusUpdate() {

      if (!this.selectedAppointment || !this.movedStatus) return;

      const payLoad = {
        id: this.selectedAppointment.id,
        data: {
          client_panel: 1,
          status: this.movedStatus.statusValue,
          reschedule_date: this.movedStatus.selectedDate,
          reschedule_time: this.convertTime12To24Format(this.movedStatus.selectedTime),
          reschedule_requested_by_customer: this.requestByCustomer ? 1 : 0,
        }
      };
      await this.appointmentStatusUpdateChargeCalculation(payLoad);
    },

    async updateAppointmentStatusHandler() {
      console.log('btn clicked');
      if (this.changedStatus.statusValue === 0 && this.selectedAppointment.technicianId === null) {
        console.log('unassigned open')
        await this.createAppointmentTechnician();
        await this.createAppointmentHistory();
        return;
      }
      if (this.changedStatus.statusValue === 10 && this.selectedAppointment.technicianId === null) {
        console.log('unassigned R & R')
        await this.createAppointmentTechnician();
      }

      // initial common property set
      let data = {
        status: parseInt(this.changedStatus.statusValue),
        user_id: this.$store.getters["appAuthLogin/authUser"].id
      };

      // add property for reschedule
      if (this.changedStatus.statusValue === 9) {
        Object.assign(data, {
          reschedule_date: this.changedStatus.selectedDate,
          reschedule_time: this.convertTime12To24Format(this.movedStatus.selectedTime),
          reschedule_requested_by_customer: this.requestByCustomer ? 1 : 0
        });
        // add property for R & R
      } else if (this.changedStatus.statusValue === 10) {
        Object.assign(data, {
          reschedule_date: this.changedStatus.selectedDate,
          reschedule_time: this.convertTime12To24Format(this.movedStatus.selectedTime),
          reschedule_requested_by_customer: this.requestByCustomer ? 1 : 0,
          technician_id: this.changedStatus.technician.id
        });
        // add property for reassign
      } else if (this.changedStatus.statusValue === 7) {
        Object.assign(data, {
          technician_id: this.changedStatus.technician.id
        });
      }

      const dataPayload = {
        id: this.selectedAppointment.id,
        data
      };

      const haveNoteValidated = this.haveNote ? this.updateUserTypeWithNoteValidation() : false;
      if (haveNoteValidated) {
        Object.assign(dataPayload.data, {
          appointment_note: this.noteDescription,
          user_type: this.selectedUserType.value,
          customer_can_view: this.visibleNoteToCustomer ? 1 : 0,
        });
      }

      console.log('status api call, appointment, history, email notify')
      await this.updateStatusByApiCall(dataPayload);

    },
    async appointmentStatusUpdateChargeCalculation(data) {
      this.loader(true);
      const response = await this.putAppointmentStatusCharge(data);

      if (response.status === 200 || response.status === 201) {
        this.statusPreUpdateChargeResponse = response.data;

        this.loader(false);

      }
      if (response.message) {
        this.showToastMessage(response);
      }
      this.loader(false);
    },

    updateUserTypeWithNoteValidation() {
      // Reset errors first
      this.frontendErrors.selectedUserType = '';
      this.frontendErrors.noteDescription = '';

      // Define messages once
      const noteMsg = 'Please enter note description';
      const userTypeMsg = 'Please select a user type';

      // Helper function for showing warnings
      const showWarning = (field, message) => {
        this.frontendErrors[field] = message;
        this.showToastMessage({
          type: 'warning',
          message: message
        });
      };

      // Validation checks
      if (!this.selectedUserType) {
        showWarning('selectedUserType', userTypeMsg);
        return false;  // Early return to prevent further checks if user type is invalid
      }

      if (!this.noteDescription) {
        showWarning('noteDescription', noteMsg);
        return false;  // Early return to prevent further checks if note description is missing
      }
      // If all validations pass
      return true;
    },

    appointmentScheduleCreate() {
      // schedule check & set last schedule
      const schedules = this.selectedAppointment?.schedules || [];
      const lastSchedule = schedules[schedules.length - 1];
      // check if type is started
      if (lastSchedule?.type === "Started") {
        this.appointmentScheduleCreate({
          appointment_id: this.selectedAppointment.id,
          time: new Date().toLocaleString("en-US", {
            hour12: false,
            hour: "2-digit",
            minute: "2-digit",
            timeZone: "Australia/Sydney",
          }),
          type: 1,
          visit_day: lastSchedule.visit_day,
        });
      }
    },

    async updateStatusByApiCall(data) {
      this.loader(true);
      await this.putAppointmentStatus(data).then(async response => {
        if (response.status === 200 && response.message.status === true) {
          await this.createAppointmentHistory();
          await this.appointmentStatusUpdateEmailNotify();
          //this.appointmentScheduleCreate()
          this.loader(false);
          this.$emit('changeStatus', {
            response,
            changedStatus: this.changedStatus,
            appointmentId: this.selectedAppointment.id
          });
          await this.toggleModal('appointmentDetailsCardModal');
          this.resetData();
        } else {
          this.loader(false);
          this.showToastMessage({
            message: `${response.message.message}`,
            type: 'error'
          });
        }
      });
    },

    async createAppointmentTechnician() {
      const data = {
        technician_id: this.movedStatus?.technician?.id || '',
        appointment_id: this.selectedAppointment.id,
      };
      this.loader(true);
      await this.postAppointmentTechnician(data).then(response => {
        this.loader(false);
        if (response.status === 200 || response.status === 201) {
          this.showToastMessage({
            message: 'Successfully assign technician on the appointment',
            type: 'success'
          });
        } else if (response.message) {
          this.showToastMessage(response);
        }
      });
    },

    async createAppointmentHistory() {
      const techId = (this.changedStatus.statusValue === 0 || this.changedStatus.statusValue === 7 || this.changedStatus.statusValue === 10) ?
          this.changedStatus.technician.id : this.selectedAppointment.technicianId;
      const data = {
        user_id: this.$store.getters["appAuthLogin/authUser"].id,
        appointment_id: this.selectedAppointment.id,
        technician_id: techId,
        panel: 1,
        status: this.changedStatus.statusValue,
      };
      await this.postAppointmentHistory(data);

    },

    async appointmentStatusUpdateEmailNotify() {
      const data = {
        id: this.selectedAppointment.id,
        params: {
          notify_customer: this.emailNotifyToCustomer ? 1 : 0,
          notify_technician: 1,
          notify_internal_user: 1
        }
      };
      await this.postAppointmentStatusUpdateNotifyEmail(data);
    },
  }
}
</script>

<template>
  <div class="modal fade" id="appointmentDetailsCardModal" data-keyboard="false" data-backdrop="static" tabindex="-1"
       aria-labelledby="appointmentDetailsCardModal" aria-hidden="true">
    <div class="modal-dialog  modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <div class="modal-title white">
            <div>
              <p><span><i class="bx bx-barcode"></i></span>{{ selectedAppointment?.reference }}</p>
              <p class="text-uppercase"><span><i class="bx bxl-spring-boot"></i></span>{{ selectedAppointment?.status }}
              </p>
              <p><span><i class="bx bx-cube"></i></span>{{ selectedAppointment?.service_name }}</p>
              <p><span><i class="bx bx-calendar"></i></span>{{ selectedAppointment?.date }}</p>
            </div>
          </div>
          <div class="modal-title white">

            <div>
              <p><span><i class="bx bx-user"></i></span>{{ selectedAppointment?.customer_name }}</p>
              <p><span><i class="bx bx-mail-send"></i></span>{{ selectedAppointment?.customer_email }}</p>
              <p><span><i class="bx bx-phone-call"></i></span>{{ selectedAppointment?.customer_phone }}</p>
              <p><span><i class="bx bx-time"></i></span>{{ selectedAppointment?.time }}</p>
            </div>

            <button @click="resetData" type="button" class="close" data-dismiss="modal" aria-label="Close">
              <i class="bx bx-x"></i>
            </button>
          </div>

        </div>
        <div class="modal-body">
          <div>
            <!-- dynamic message from server whereas shown details changes -->
            <div class="col-12 border rounded-sm border-darken-4 pt-1 align-items-center">
              <h6 class="text-danger">
                <i>{{ statusPreUpdateChargeResponse?.message ? statusPreUpdateChargeResponse?.message : '' }}</i></h6>
              <ul class="pl-1">
                <li class="" v-for="(value, name,index) in statusPreUpdateChargeResponse?.details" :key="index">
                  <span class="text-capitalize"> {{ name.replaceAll("_", " ") }} </span> : ${{ value }}
                </li>
              </ul>
            </div>

            <div class="col-12 divider divider-dotted divider-danger m-0">
              <div class="divider-text"><i class="bx bx-star"></i></div>
            </div>
            <!-- status details section-->
            <div class="accordion collapse-icon accordion-icon-rotate" id="cardAccordion" data-toggle-hover="true">
              <div class="card">
                <div class="card-header" id="headingOne" data-toggle="collapse" data-target="#collapseOne"
                     aria-expanded="false" aria-controls="collapseOne" role="button">
                  <span class="collapsed collapse-title">{{ changedStatus?.statusText }}</span>
                </div>
                <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#cardAccordion">
                  <div class="card-body m-0 py-1 px-1 d-flex justify-content-between"
                       style="background-color: rgb(144 165 204 / 35%)">
                    <div>
                      <span><i class="bx bxs-user mr-1"></i></span>
                      <span>{{ movedStatus?.technician?.name }}</span></div>
                    <div>
                      <span><i class="bx bx-calendar mr-1"></i></span>
                      <span>{{ movedStatus.selectedDate }}</span></div>
                    <div>
                      <span><i class="bx bxs-time mr-1"></i></span>
                      <span>{{ movedStatus.selectedTime }}</span></div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="changedStatus.statusValue && changedStatus.statusText !== 'Assigned'">
              <!-- switch toggle action section-->
              <div class="row  m-0 text-center">

                <div v-if="isVisibleRequestByCustomer" class="col-4 text-warning text-bold-600 p-0">
                  <div class="form-group">
                    <div class="controls">
                      <label>Request by customer</label>
                      <div style="margin-top: .5rem;">
                        <div
                            class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center justify-content-center">
                          <div>
                            <input v-model="requestByCustomer" type="checkbox" class="custom-control-input" checked
                                   id="requestByCustomer">
                            <label class="custom-control-label mr-1" for="requestByCustomer"></label>
                          </div>
                          <span class="font-medium-1">{{ requestByCustomer ? "Yes" : "No" }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-4 text-warning text-bold-600 p-0">
                  <div class="form-group">
                    <div class="controls">
                      <label>Email Notify</label>
                      <div style="margin-top: .5rem">
                        <div
                            class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center justify-content-center">
                          <div>
                            <input v-model="emailNotifyToCustomer" type="checkbox" class="custom-control-input" checked
                                   id="emailNotifyToCustomer">
                            <label class="custom-control-label mr-1" for="emailNotifyToCustomer"></label>
                          </div>
                          <span class="font-medium-1">{{ emailNotifyToCustomer ? "Yes" : "No" }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-4 text-warning text-bold-600 p-0">
                  <div class="form-group">
                    <div class="controls">
                      <label>Add Note</label>
                      <div style="margin-top: .5rem">
                        <div
                            class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center justify-content-center">
                          <div>
                            <input v-model="haveNote" type="checkbox" class="custom-control-input" checked
                                   id="haveNote">
                            <label class="custom-control-label mr-1" for="haveNote"></label>
                          </div>
                          <span class="font-medium-1">{{ haveNote ? "Yes" : "No" }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- note section -->
              <div class="row" v-show="haveNote">

                <div class="col-8 form-group">
                  <label>User Type</label>
                  <VueMultiselect v-model="selectedUserType" :allow-empty="false" :options="userAllType"
                                  :close-on-select="true"
                                  placeholder="Select type" label="name" track-by="value" :show-labels="false"/>
                </div>

                <div class="col-4 text-warning text-bold-600 p-0">
                  <div class="form-group text-center">
                    <div class="controls">
                      <label>Visible note to customer</label>
                      <div style="margin-top: .5rem">
                        <div
                            class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center justify-content-center">
                          <div>
                            <input v-model="visibleNoteToCustomer" type="checkbox" class="custom-control-input" checked
                                   id="visibleNoteToCustomer">
                            <label class="custom-control-label mr-1" for="visibleNoteToCustomer"></label>
                          </div>
                          <span class="font-medium-1">{{ visibleNoteToCustomer ? "Yes" : "No" }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <label>Note</label>
                    <textarea v-model="noteDescription" class="form-control" name="noteDescription" id="noteDescription"
                              rows="5"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 pt-0">
          <button type="button" class="btn btn-primary"
                  @click="updateAppointmentStatusHandler">
            <span class="">Update Status</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.modal-header {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.modal-title {
  display: flex;
  justify-content: space-between;
}

.modal-title p {
  font-size: 14px;
  line-height: 15px;
  margin-bottom: 0;
}

</style>